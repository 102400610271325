import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {removeNotification} from "../../../services/app/actions";

const NotistackNotifier = () => {

    const dispatch = useDispatch();
    const notifications = useSelector(store => store.app.notifications);
    const { enqueueSnackbar } = useSnackbar();

    notifications.forEach(({key, message, config}) => {
        dispatch(removeNotification(key));
        enqueueSnackbar(message, config);
    });

    return null;
};

export default NotistackNotifier;

import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {customerComplaintStep2Submit} from "../../../../../services/customer-complaint/actions";
import {t} from "ttag";
import ClaimsForm from "./claims-form/claims-form";

const Step2 = ({onSubmit}) => {

    return (
        <Grid container spacing={1}>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Select the reason for your complaint.`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <ClaimsForm onSubmit={onSubmit}/>
            </Grid>
        </Grid>
    );
};

Step2.propTypes = {
    onSubmit: PropTypes.func,
};

Step2.defaultProps = {
    onSubmit: () => null,
};

export default connect(null, {onSubmit: customerComplaintStep2Submit})(Step2);

import React from 'react';
import ArrowButton from "../../../components/arrow-button/arrow-button";
import {t} from 'ttag';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {setAppScene} from "../../../services/app/actions";
import {APP_SCENES} from "../../../services/app/default-state";
import styles from './ComplaintFormSelector.module.scss';
import PropTypes from 'prop-types';

const ComplaintFormSelector = ({currentAppScene, setAppScene}) => {
    return (
        <Grid spacing={5} container>
            <Grid xs={12} sm={6} md={6} className={styles.btnContainer} item>
                <ArrowButton
                    className={styles.btn}
                    variant={'contained'}
                    onClick={() => setAppScene(APP_SCENES.COMPLAINT_WITH_RESERVATION)}
                    selected={currentAppScene === APP_SCENES.COMPLAINT_WITH_RESERVATION}>
                    {t`Complaint about a reservation`}
                </ArrowButton>
            </Grid>
            <Grid xs={12} sm={6} md={6} className={styles.btnContainer} item>
                <ArrowButton
                    className={styles.btn}
                    variant={'contained'}
                    onClick={() => setAppScene(APP_SCENES.COMPLAINT_WITHOUT_RESERVATION)}
                    selected={currentAppScene === APP_SCENES.COMPLAINT_WITHOUT_RESERVATION}>
                    {t`I don't have a reservation`}
                </ArrowButton>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <br />
            </Grid>
        </Grid>
    );
};

ComplaintFormSelector.propTypes = {
    currentAppScene: PropTypes.string,
    setAppScene: PropTypes.func,
};

ComplaintFormSelector.defaultProps = {
    currentAppScene: APP_SCENES.EMPTY,
    setAppScene: () => null,
};

export default connect((state) => ({currentAppScene: state.app.appScene}), {setAppScene})(ComplaintFormSelector);

import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {customerComplimentStep1Submit} from "../../../../../services/customer-compliment/actions";
import CustomerForm from "../../../common/customer-form/customer-form";
import {t} from "ttag";

const Step1 = ({customer, onSubmit}) => {

    return (
        <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Enter your details.`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <CustomerForm customer={customer} onSubmit={onSubmit} />
            </Grid>
        </Grid>
    );
};

Step1.propTypes = {
    onSubmit: PropTypes.func,
    customer: PropTypes.object,

};

Step1.defaultProps = {
    onSubmit: () => null,
    customer: undefined,

};

export default connect(state => ({customer: state.customerCompliment?.complimentInfo?.customer}), {onSubmit: customerComplimentStep1Submit})(Step1);


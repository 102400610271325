import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import styles from './language-dropdown.module.scss';
import {t} from 'ttag';
import APP_SETTINGS from "../../config/settings";


const LanguageDropdown = ({label, value, onChange, error, isOnDark}) => {

    const changeLangHandler = lang => {
        APP_SETTINGS.SET_LANGUAGE(lang);
        onChange(lang);
        window.location.reload();
    };

    return (
        <FormControl fullWidth error={error}>
            {/*<InputLabel style={{color: isOnDark ? 'white' : 'black'}}>{label}</InputLabel>*/}
            <Select
                className={isOnDark ? styles.selectLanguageOnDark : ''}
                value={value || APP_SETTINGS.CURRENT_LANGUAGE}
                onChange={(e) => changeLangHandler(e.target.value)}>
                <MenuItem value={'en'}>{t`EN`}</MenuItem>
                <MenuItem value={'pt'}>{t`PT`}</MenuItem>
                <MenuItem value={'fr'}>{t`FR`}</MenuItem>
            </Select>
        </FormControl>
    );
};

LanguageDropdown.propTypes = {
    error: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
    onSelect: PropTypes.func,
    required: PropTypes.bool,
    isOnDark: PropTypes.bool,
};

LanguageDropdown.defaultProps = {
    error: false,
    label: 'Language',
    value: null,
    onChange: () => console.warn('function not injected'),
    required: false,
    isOnDark: false,
};

export default LanguageDropdown;

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Loading from "./components/loading/loading";
import {Container} from "@material-ui/core";
import styles from './app.module.scss';
import NotistackProvider from "./components/notistack/notistack-provider/notistack-provider";
import RouteSwitcher from "./scenes/RouteSwitcher/RouteSwitcher";
import {connect} from "react-redux";
import {retrieveClaimsLists} from "./services/app/actions";

export const App = ({retrieveClaimsLists}) => {

    useEffect(() => {
        retrieveClaimsLists();
    }, [retrieveClaimsLists])

    return (
        <Container maxWidth={'md'} className={styles.container}>
            <NotistackProvider>
                <Loading/>
                <RouteSwitcher/>
            </NotistackProvider>
        </Container>
    );
};

App.propTypes = {
    retrieveClaimsLists: PropTypes.func,
};

App.defaultProps = {
    retrieveClaimsLists: () => null
}

export default connect(null, {retrieveClaimsLists})(App);

import React, {useEffect, useState} from 'react';
import {Container, TextField, Button, Grid} from "@material-ui/core";
import {DATA, DATA_FILLED, DATA_VALIDATOR, isAllValid} from "./pnr-form-data";
import PropTypes from 'prop-types';
import styles from './pnr-form.module.scss';
import {t} from 'ttag';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import APP_SETTINGS from "../../../../../../config/settings";

const PnrForm = ({onSubmit, isAGroup, ticketNumberError}) => {

    const RESERVATION_CODE_MAX_CHARS = 13;

    const [needAttention, setNeedAttention] = useState(false);
    const [localTicketNumberError, setLocalTicketNumberError] = useState(ticketNumberError);
    const [data, setData] = useState(APP_SETTINGS.DEBUG ? {...DATA_FILLED} : {...DATA});

    const onChange = (fieldId, value) => {
        if ((fieldId === 'pnr' || fieldId === 'ticketNumber') && value.length > RESERVATION_CODE_MAX_CHARS) {
            value = value.substring(0, RESERVATION_CODE_MAX_CHARS)
        }

        setData({
            ...data,
            [fieldId]: value
        });
    };

    useEffect(() => {
        setLocalTicketNumberError(ticketNumberError);
    }, [setLocalTicketNumberError, ticketNumberError]);

    const localSubmitHandler = () => {
        if (isAllValid(data, isAGroup)) {
            onSubmit({...data});
        } else {
            setNeedAttention(true);
        }
    };

    const onKeyPressed = (e) => {
        if (e.key === 'Enter') {
            localSubmitHandler();
        }
    };

    return (
        <Container>
            <Grid spacing={2} container>
                <Grid xs={12} sm={6} md={6} item>
                    <TextField
                        label={t`Booking reference`}
                        value={data.pnr}
                        disabled={isAGroup}
                        error={needAttention && !DATA_VALIDATOR.pnr(data.pnr)}
                        helperText={needAttention && !DATA_VALIDATOR.pnr(data.pnr) ? t`Please check field data` : t`Ex. X2C1V3 or 3310123456789`}
                        onChange={e => onChange('pnr', e.target.value)}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid xs={12} sm={6} md={6} item>
                    <TextField
                        label={t`Last name`}
                        value={data.lastName}
                        disabled={isAGroup}
                        error={needAttention && !DATA_VALIDATOR.lastName(data.lastName)}
                        helperText={needAttention && !DATA_VALIDATOR.lastName(data.lastName) ? t`Please check field data` : ''}
                        onChange={e => onChange('lastName', e.target.value)}
                        onKeyPress={onKeyPressed}
                        required
                        fullWidth
                    />
                </Grid>
                {isAGroup && (
                    <Grid xs={12} sm={6} md={6} item>
                        <TextField
                            label={t`Ticket number`}
                            value={data.ticketNumber}
                            error={localTicketNumberError || (needAttention && !DATA_VALIDATOR.ticketNumber(data.ticketNumber))}
                            helperText={needAttention && !DATA_VALIDATOR.ticketNumber(data.ticketNumber) ? t`Please check field data` : t`This is a group reservation, please insert your ticket number. (Ex. 3310123456789)`}
                            onChange={e => onChange('ticketNumber', e.target.value)}
                            onKeyPress={onKeyPressed}
                            required
                            autoFocus
                            fullWidth
                        />
                    </Grid>
                )}
                <Grid xs={12} sm={12} md={12} item>
                    <Button
                        className={styles.submitBtn}
                        variant="contained"
                        color="secondary"
                        onClick={localSubmitHandler}
                        endIcon={<NavigateNextIcon/>}>
                        {/*{t`Select passenger(s)`}*/}
                        {t`Next`}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

PnrForm.propTypes = {
    onSubmit: PropTypes.func,
    isAGroup: PropTypes.bool,
    ticketNumberError: PropTypes.bool,
};

PnrForm.defaultProps = {
    onSubmit: () => null,
    isAGroup: false,
    ticketNumberError: false,
};

export default PnrForm;
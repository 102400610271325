import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {t} from "ttag";
import AdditionalInfoAndAttachmentsForm
    from "./additional-info-and-attachments-form/additional-info-and-attachments-form";

const Step4 = () => {

    return (
        <Grid container spacing={1}>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`All information is important and can help us improve. Use this field to provide more detailed information about your experience.`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <AdditionalInfoAndAttachmentsForm/>
            </Grid>
        </Grid>
    );
};

export default Step4;

import PropTypes from 'prop-types';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import {connect} from "react-redux";

const Loading = ({loading, addEndListener}) => {

    if (!loading) {
        return null;
    }

    return (
        <Backdrop addEndListener={addEndListener} transitionDuration={100} timeout={100} open={loading} style={{zIndex: 100}}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
};

Loading.propTypes = {
    loading: PropTypes.bool,
    addEndListener: PropTypes.func,
};

Loading.defaultProps = {
    loading: true,
    addEndListener: () => null
};

export default connect(state => ({loading: state.app.loading}))(Loading);

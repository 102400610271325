import React from 'react';
import PropTypes from 'prop-types';
import {Button, Step, StepContent, StepLabel, Stepper as StepperMui, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import {setComplaintCurrentStep} from "../../../../../services/customer-complaint/actions";
import APP_SETTINGS from "../../../../../config/settings";
import {t} from "ttag";
import Step1 from "../step-1/step-1";
import Step2 from "../step-2/step-2";
import Step3 from "../step-3/step-3";
import styles from '../../../common/stepper/stepper.module.scss';

const STEPS = {
    STEP1: {label: t`Customer`, content: <Step1/>},
    STEP2: {label: t`Complaint`, content: <Step2/>},
    STEP3: {label: t`Additional Details`, content: <Step3/>},
};


const Stepper = ({steps, step, handleGoTo, handleBack, handleNext, handleReset, finishContent, hideBackBtn, hideNextBtn, allStepsOpen}) => {

    const stepLabelExtraProps = {StepIconProps: {classes: {active: styles.stepIcon, completed: styles.stepIcon}}};
    if (allStepsOpen) stepLabelExtraProps.active = true;

    return (
        <div>
            <StepperMui activeStep={step} style={{padding: 0, backgroundColor: 'transparent'}} orientation="vertical">
                {Object.values(steps).map(({label, navAllowed, content}, idx) => (
                    <Step key={idx}>
                        {(APP_SETTINGS.DEBUG || idx <= step)
                            ? (
                                <StepLabel onClick={() => handleGoTo(idx)} style={{cursor: 'pointer'}} {...stepLabelExtraProps}>
                                    {label}
                                </StepLabel>
                            ) : (
                                <StepLabel {...stepLabelExtraProps}>
                                    {label}
                                </StepLabel>
                            )
                        }
                        <StepContent TransitionProps={allStepsOpen ? {in: true} : undefined}>
                            {content}
                            {!(hideBackBtn && hideNextBtn) && (
                                <div>
                                    <br/>
                                    {!hideBackBtn && (
                                        <Button
                                            disabled={step === 0}
                                            onClick={handleBack}>
                                            {t`Back`}
                                        </Button>
                                    )}
                                    {!hideNextBtn && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleNext}>
                                            {step === steps.length - 1 ? t`Finish` : t`Next`}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </StepContent>
                    </Step>
                ))}
            </StepperMui>
            {step >= steps.length && (
                <>
                    {finishContent}
                    <Button onClick={handleReset}>
                        {t`Start over`}
                    </Button>
                </>
            )}
        </div>
    );
};

Stepper.propTypes = {
    steps: PropTypes.array,
    step: PropTypes.number,
    allStepsOpen: PropTypes.bool,
    finishContent: PropTypes.any,
    hideBackBtn: PropTypes.bool,
    hideNextBtn: PropTypes.bool,
    handleGoTo: PropTypes.func,
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
    handleReset: PropTypes.func,
};

Stepper.defaultProps = {
    steps: Object.values(STEPS),
    step: 0,
    allStepsOpen: false,
    finishContent: <Typography>{t`Your claim was submitted.`}</Typography>,
    hideBackBtn: true,
    hideNextBtn: true,
    handleGoTo: () => null,
    handleBack: () => null,
    handleNext: () => null,
    handleReset: () => null,
};

export default connect(state => ({step: state.customerComplaint.complaintCurrentStep}), {
    handleGoTo: setComplaintCurrentStep,
    handleReset: () => setComplaintCurrentStep(0)
})(Stepper);

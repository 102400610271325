import React from 'react';
import PropTypes from 'prop-types';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ProcessStateForm from "./process-state/process-state-form";
import {connect} from "react-redux";
import {customerProcessStateFormSubmit} from "../../../../services/customer-process-state/actions";

const Step1 = ({onSubmit, processNumber}) => {
    return (
        <Container>
            <Grid container>
                <Grid xs={12} sm={12} md={12} item>
                    <ProcessStateForm
                        onSubmit={({processNumber}) => onSubmit(processNumber)}
                        processNumber={processNumber} />
                </Grid>
            </Grid>
        </Container>
    );
};

Step1.propTypes = {
    onSubmit: PropTypes.func,
    processNumber: PropTypes.string,
};

Step1.defaultProps = {
    onSubmit: () => null,
    processNumber: '',
};

export default connect(state => ({processNumber: state.customerProcessState.processNumber}), {onSubmit: customerProcessStateFormSubmit})(Step1);
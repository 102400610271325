import React from 'react'
import {withWidth} from "@material-ui/core";

const withScreen = (PassedComponent) => (
    withWidth()(({width, ...props}) => {
        const screen = {
            width,
            breakpoints: {XS: 'XS', SM: 'SM', MD: 'MD', LG: 'LG', XL: 'XL'},
            breakpointsList: ['XS', 'SM', 'MD', 'LG', 'XL'],
            from: bp => screen.breakpointsList.indexOf(width.toUpperCase()) >= screen.breakpointsList.indexOf(bp.toUpperCase()),
            upTo: bp => screen.breakpointsList.indexOf(width.toUpperCase()) <= screen.breakpointsList.indexOf(bp.toUpperCase()),
            match: bpList => bpList.findIndex(b => b.toUpperCase() === width.toUpperCase()) !== -1
        };

        return <PassedComponent {...props} screen={screen}/>;
    })
);

export default withScreen;
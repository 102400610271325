import {t} from 'ttag';
import React from 'react';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormWasSubmittedMessage from "../FormWasSubmittedMessage/FormWasSubmittedMessage";

const ProcessStateDetails = ({processNumberDetails}) => {

    if (!processNumberDetails) {
        return <CircularProgress/>;
    }
    const statusHeader = (value) => {
        switch (value.toLowerCase()) {
            case 'new':
                return t`Received / In analysis`;
            case 'internal reply received':
                return t`Received / In analysis`;
            case 'waiting for information':
                return t`Received / In analysis`;
            case 'in analysis':
                return t`Received / In analysis`;
            case 'customer response received':
                return t`Final analysis`;
            case 'reply sent':
                return t`Reply Sent`;
            case 'payment of compensation':
                return t`Case closed`;
            case 'voucher assigned':
                return t`Case closed`;
            default:
                return t`Your process is in an unknown state`;
        }
    }


    return (
        <FormWasSubmittedMessage title={processNumberDetails?.processNumber}
                                 subtitle={statusHeader(processNumberDetails.response['Status Reason'])}>
        </FormWasSubmittedMessage>
    );
};

export default connect(state => ({processNumberDetails: state.app.processNumberDetails}))(ProcessStateDetails);
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        maxWidth: 400,
        minWidth: 344,
    },
    typography: {
        fontWeight: 'bold',
    },
    actionRoot: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#313131',
        color: '#ffffff',
    },
    actionRootDefault: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#313131',
        color: '#ffffff',
    },
    actionRootError: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#d32f2f',
        color: '#ffffff',
    },
    actionRootSuccess: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#43a047',
        color: '#ffffff',
    },
    actionRootWarning: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#fddc6c',
        color: '#ffffff',
    },
    actionRootInfo: {
        padding: '8px 8px 8px 16px',
        backgroundColor: '#2979ff',
        color: '#ffffff',
    },
    icons: {
        minWidth: '80px',
        marginLeft: 'auto!important',
    },
    expand: {
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        padding: 0,
    },
    checkIcon: {
        fontSize: 20,
        color: '#b3b3b3',
        paddingRight: 4,
    },
    button: {
        padding: 0,
        textTransform: 'none',
    },
}));

const capitalize = s => {
    if (typeof s !== 'string') return s;
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export {useStyles, capitalize};

import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import AdditionalInfoAndAttachmentsForm from "./additional-info-and-attachments-form/additional-info-and-attachments-form";
import {t} from "ttag";
import {connect} from "react-redux";
import {submitCustomerComplimentData} from "../../../../../services/customer-compliment/actions";

const Step2 = () => {

    return (
        <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`All information is important and can help us improve. Use this field to provide detailed information about your experience.`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <AdditionalInfoAndAttachmentsForm/>
            </Grid>
        </Grid>
    );
};

export default connect(null, {onSubmit: submitCustomerComplimentData()})(Step2);

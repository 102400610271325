import React, {useEffect, useState} from 'react';
import {Checkbox, TextField, Typography} from "@material-ui/core";
import {DATA_VALIDATOR, TYPES_MAP} from './passenger-row-data';
import PropTypes from 'prop-types';
import APP_SETTINGS from "../../../../../../../config/settings";
import PhoneNumberInput from "../../../../../../../components/phone-number-input/phone-number-input";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
// import FormControl from "@material-ui/core/FormControl";
// import {KeyboardDatePicker} from "@material-ui/pickers";
// import {t} from 'ttag';
import moment from "moment";

const PassengerTableRow = ({onPaxChange, needAttention, onSelect, selected, passenger, readOnly, disabled, callOnPaxChangeWhenType}) => {

    const [data, setData] = useState({
        ...passenger
    });

    useEffect(() => {
        setData({...passenger});
    }, [passenger]);

    if (!passenger || !data) {
        return null;
    }

    const onChange = (fieldId, value, isDate = false) => {
        if (isDate) value.setHours(12);
        const modifiedData = {
            ...data,
            [fieldId]: isDate ? moment(value) : value
        };

        setData({...modifiedData});
        if (callOnPaxChangeWhenType || isDate) {
            onPaxChange({...modifiedData});
        }
    };

    return(
        <TableRow key={data.passengerId}>
            <TableCell padding={'checkbox'}>
                <Checkbox
                    onChange={(e, value) => onSelect(!!value)}
                    checked={selected}
                    value="secondary"
                    color="secondary"
                />
            </TableCell>
            <TableCell component={'th'} padding={'none'} scope={'row'} title={`${data.dateOfBirth?.format(APP_SETTINGS.DATE_FORMAT) || ''} ${TYPES_MAP(data.type)}`}>
                <div style={{minWidth: '110px', width: 'auto', opacity: disabled ? '.6' : '1'}}>
                    <Typography variant={'caption'} color="textSecondary">
                         {data.title}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                        {data.firstName}&nbsp;{data.lastName}
                    </Typography>
                </div>
            </TableCell>
            <TableCell>
                <PhoneNumberInput
                    //label={t`Contact`}
                    //placeholder={t`Contact`}
                    //helperText={needAttention && !DATA_VALIDATOR.contact(data.contact) ? t`Please check field data` : t`Ex. 926542554`}
                    error={needAttention && !DATA_VALIDATOR.contact(data.contact)}
                    onChange={(phoneNumber, country) => onChange('contact', phoneNumber)}
                    onBlur={() => onPaxChange({...data})}
                    value={data.contact ? data.contact : ''}
                    defaultCountry={'pt'}
                    disabled={disabled}
                    readOnly={readOnly}
                />
            </TableCell>
            {/*<TableCell>
                <FormControl fullWidth>
                    <KeyboardDatePicker
                        cancelLabel={t`Cancel`}
                        okLabel={t`Ok`}
                        disableFuture
                        //placeholder={'1980-01-20'}
                        maxDateMessage={t`Date should be in the past.`}
                        //label={t`Date of birth`}
                        error={needAttention && !DATA_VALIDATOR.dateOfBirth(data.dateOfBirth)}
                        onBlur={() => onPaxChange({...data})}
                        onChange={dateTime => onChange('dateOfBirth', dateTime, true)}
                        value={data.dateOfBirth ? new Date(data.dateOfBirth.format(APP_SETTINGS.DATE_TIME_FORMAT)) : null}
                        format="yyyy-MM-dd"
                        ampm={false}
                        disabled={disabled}
                        required={!readOnly}
                        readOnly={readOnly}
                        inputProps={{
                            readOnly: Boolean(readOnly),
                            disabled: Boolean(readOnly),
                        }}
                        fullWidth/>
                </FormControl>
            </TableCell>*/}
            <TableCell>
                <TextField
                    disabled={disabled}
                    //label={t`Email`}
                    //placeholder={t`Email`}
                    fullWidth
                    style={{minWidth: '130px'}}
                    value={data.email ? data.email : ''}
                    error={needAttention && !DATA_VALIDATOR.email(data.email)}
                    //helperText={needAttention && !DATA_VALIDATOR.email(data.email) ? t`Please check field data` : ''}
                    onChange={(e) => onChange('email', e.target.value)}
                    onBlur={() => onPaxChange({...data})}
                    required
                    InputProps={{
                        readOnly: readOnly
                    }}
                />
            </TableCell>
            <TableCell>
                <TextField
                    disabled={disabled}
                    //label={t`FQTV`}
                    //placeholder={t`FQTV`}
                    fullWidth
                    style={{minWidth: '100px'}}
                    value={data.frequentFlyer ? data.frequentFlyer : ''}
                    error={needAttention && !DATA_VALIDATOR.frequentFlyer(data.frequentFlyer)}
                    //helperText={needAttention && !DATA_VALIDATOR.frequentFlyer(data.frequentFlyer) ? t`Please check field data` : ''}
                    onChange={(e) => onChange('frequentFlyer', e.target.value)}
                    onBlur={() => onPaxChange({...data})}
                    InputProps={{
                        readOnly: readOnly
                    }}
                />
            </TableCell>
        </TableRow>
    );
};

PassengerTableRow.propTypes = {
    passenger: PropTypes.object,
    needAttention: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onSelect: PropTypes.func,
    onPaxChange: PropTypes.func,
    callOnPaxChangeWhenType: PropTypes.bool,
};

PassengerTableRow.defaultProps = {
    passenger: null,
    needAttention: false,
    selected: false,
    disabled: false,
    readOnly: false,
    onSelect: () => null,
    onPaxChange: () => null,
    callOnPaxChangeWhenType: false
};

export default PassengerTableRow;
import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import FlightsList from "./flights-form/flight-list/flight-list";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {setModifiedFlightInfoFlights} from "../../../../../services/customer-claims/actions";
import {t} from "ttag";

const Step3 = ({onSubmit}) => {

    return (
        <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Select the flights of the trip related to your complaint or suggestion.`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <FlightsList onSubmit={onSubmit}/>
            </Grid>
        </Grid>
    );
};


Step3.propTypes = {
    onSubmit: PropTypes.func,
};

Step3.defaultProps = {
    onSubmit: () => null,
};

export default connect(null, {onSubmit: setModifiedFlightInfoFlights})(Step3);

import React, {useState, createRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {Button, TextField} from "@material-ui/core";
import {t} from 'ttag';
import {DATA, DATA_VALIDATOR, IS_PROCESS_VALID} from "./process-state-form-data";
import styles from './process-state-form.module.scss'
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const ProcessStateForm = ({onSubmit, processNumber, processNumberMaxChars}) => {

    const fieldRef = createRef();
    const [needAttention, setNeedAttention] = useState(false);
    const [data, setData] = useState({
        ...DATA
    });

    useEffect(() => {
        setData({
            processNumber: processNumber
        });
    }, [processNumber]);

    const onChange = (fieldId, value) => {
        if (fieldId === 'processNumber' && value.length > processNumberMaxChars) {
            value = value.substring(0, processNumberMaxChars);
        }

        setData({
            ...data,
            [fieldId]: value
        });
    };

    const localHandlerSubmit = () => {
        if (IS_PROCESS_VALID(data)) {
            onSubmit({...data});
        } else {
            setNeedAttention(true);
            fieldRef.current.focus();
        }
    }

    const onKeyPressed = (e) => {
        if (e.key === 'Enter') {
            localHandlerSubmit();
        }
    };

    return (
       <Container>
           <Grid spacing={2} container>
               <Grid xs={12} sm={12} md={12} item>
                   <TextField
                       InputLabelProps={{shrink: true}}
                       label={t`Process Number`}
                       value={data.processNumber}
                       error={needAttention && !DATA_VALIDATOR.processNumber(data.processNumber)}
                       helperText={needAttention && !DATA_VALIDATOR.processNumber(data.processNumber) ? t`Please check your field data` : ''}
                       onChange={e => onChange('processNumber', e.target.value)}
                       onKeyPress={onKeyPressed}
                       inputRef={fieldRef}
                       required
                       fullWidth
                   />
               </Grid>
               <Grid xs={12} sm={12} md={12} item>
                   <Button
                       className={styles.submitBtn}
                       variant="contained"
                       color="secondary"
                       onClick={localHandlerSubmit}
                       endIcon={<NavigateNextIcon/>}>
                       {t`Submit`}
                   </Button>
               </Grid>
           </Grid>
       </Container>
    );
};

ProcessStateForm.propTypes = {
    onSubmit: PropTypes.func,
    processNumber: PropTypes.string,
    processNumberMaxChars: PropTypes.number,
};

ProcessStateForm.defaultProps = {
    onSubmit: () => null,
    processNumber: '',
    processNumberMaxChars: Number.MAX_SAFE_INTEGER,
};

export default ProcessStateForm;
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styles from "../../step-3/additional-info-and-attachments-form/additional-info-and-attachments-form.module.scss";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {t} from "ttag";
import {IS_FORM_VALID} from "./claims-form-data";
import ClaimTypeAutocomplete from "../../../../../../components/claims-autocomplete/claim-type-autocomplete/claim-type-autocomplete";
import ClaimSubtypeAutocomplete from "../../../../../../components/claims-autocomplete/claim-subtype-autocomplete/claim-subtype-autocomplete";
import {connect} from "react-redux";

const ClaimsForm = ({onSubmit, claimSubtype, claimSubjects}) => {

    const [data, setData] = useState({claimType: null, claimSubtype});
    const [needAttention, setNeedAttention] = useState(false);

    useEffect(() => {
        if (claimSubjects.length && claimSubtype) {
            const claimType = claimSubjects.find(s => s.subjectTypeDetails.some(d => d.reasonId === claimSubtype));
            setData({claimType: claimType?.subjectType, claimSubtype});
        }
    }, [claimSubjects, claimSubtype]);

    const localDataHandler = (fieldId, value) => {
        const newData = {
            ...data,
            [fieldId]: value
        };

        setData(newData);
    };

    const localSubmitHandler = () => {
        if (IS_FORM_VALID(data)) {
            onSubmit(data?.claimSubtype);
        } else {
            setNeedAttention(true);
        }
    }

    return (
        <Grid spacing={1} container>
            <Grid xs={12} sm={6} item>
                <ClaimTypeAutocomplete
                    label={t`Type`}
                    subjectTypes={claimSubjects}
                    value={data.claimType}
                    onSelect={v => localDataHandler('claimType', v)}
                    error={needAttention && !data.claimType}
                    fullWidth/>
            </Grid>
            <Grid xs={12} sm={6} item>
                <ClaimSubtypeAutocomplete
                    label={t`Reason`}
                    subjectTypes={claimSubjects}
                    claimType={data.claimType}
                    value={data.claimSubtype}
                    error={needAttention && !data.claimSubtype}
                    onSelect={v => localDataHandler('claimSubtype', v)}
                    fullWidth/>
            </Grid>
            <Grid xs={12} sm={12} md={12} align={'right'} item>
                <Button
                    className={styles.submitBtn}
                    variant="contained"
                    color="secondary"
                    onClick={localSubmitHandler}
                    endIcon={<NavigateNextIcon/>}>
                    {t`Next`}
                </Button>
            </Grid>
        </Grid>
    );
};

ClaimsForm.propTypes = {
    onSubmit: PropTypes.func,
    claimSubtype: PropTypes.any,
    claimSubjects: PropTypes.array,
};

ClaimsForm.defaultProps = {
    onSubmit: () => null,
    claimSubtype: null,
    claimSubjects: []
};

export default connect(state => ({claimSubjects: state.app.subjectTypesListFiltered, claimSubtype: state.customerComplaint?.complaintInfo?.classification}))(ClaimsForm);

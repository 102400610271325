import {useLocale, addLocale, setDefaultLang} from 'ttag';
import APP_SETTINGS from "./config/settings";

setDefaultLang(APP_SETTINGS.DEFAULT_LANGUAGE);
const addLocales = () => {
    const locales = Object.values(APP_SETTINGS.LANGUAGES);
    locales.forEach(locale => {
        const translation = require(`../i18n/${locale}.po.json`);
        addLocale(locale, translation);
    })
};

addLocales();
useLocale(APP_SETTINGS.CURRENT_LANGUAGE);

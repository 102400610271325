import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Checkbox, Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import styles from './passengers-list.module.scss';
import {t} from 'ttag';
import {PASSENGERS_VALIDATOR} from "./passenger-list-data";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import PassengerTableRow from "../passenger-form/passenger-table-row";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import APP_SETTINGS from "../../../../../../../config/settings";
import withScreen from "../../../../../../../components/hoc/withScreen";
import PassengerMobileRow from "../passenger-form/passenger-mobile-row";

const PassengersList = ({onSubmit, passengers, screen}) => {

    const containerRef = useRef();
    const [needAttention, setNeedAttention] = useState(false);
    const [localPassengers, setLocalPassengers] = useState([
        ...passengers.map(p => ({selected: false, ...p}))
    ]);

    if (!passengers || (Array.isArray(passengers) && !passengers.length)) {
        return null;
    }

    const onChange = (idx, passenger) => {
        localPassengers[idx] = passenger;
        setLocalPassengers([...localPassengers]);
    };

    const localSubmitHandler = () => {
        if (PASSENGERS_VALIDATOR(localPassengers)) {
            onSubmit([...localPassengers]);
        } else {
            window.scrollTo(0, containerRef.current.offsetTop);
            setNeedAttention(true);
        }
    };

    const selectAll = (allSelected) => {
        setLocalPassengers([...localPassengers.map(p => ({...p, selected: allSelected}))]);
    };

    const responsiveRender = () => {
        if (screen.upTo(screen.breakpoints.SM)) {
            return (
                <Grid spacing={2} container>
                    {localPassengers.map((p, idx) => (
                        <Grid xs={12} sm={12} md={12} key={idx} item>
                            <PassengerMobileRow
                                key={idx}
                                onPaxChange={(modifiedPassenger) => onChange(idx, {...modifiedPassenger})}
                                onSelect={(v) => onChange(idx, {...p, selected: v})}
                                needAttention={needAttention && p.selected}
                                selected={p.selected}
                                disabled={!p.selected}
                                passenger={p}/>
                        </Grid>
                    ))}
                </Grid>
            );
        }

        return (
            <TableContainer className={styles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <Checkbox
                                    onChange={(e, value) => selectAll(!!value)}
                                    //checked={selected}
                                    value="secondary"
                                    color="secondary"
                                />
                            </TableCell>
                            <TableCell padding={'none'}>
                                {t`Name`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Phone`}
                            </TableCell>
                            {/*<TableCell padding={'none'} align={'center'}>
                                    {t`Date of Birth`}
                                </TableCell>*/}
                            <TableCell padding={'none'} align={'center'}>
                                {t`Email`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Frequent Flyer Number`}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localPassengers.map((p, idx) => (
                            <PassengerTableRow
                                key={idx}
                                onPaxChange={(modifiedPassenger) => onChange(idx, {...modifiedPassenger})}
                                onSelect={(v) => onChange(idx, {...p, selected: v})}
                                needAttention={needAttention && p.selected}
                                selected={p.selected}
                                disabled={!p.selected}
                                passenger={p}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };
    return (
        <Grid ref={containerRef} container>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={APP_SETTINGS.CURRENT_LOCALE}>
                <Grid xs={12} sm={12} md={12} item>
                    {responsiveRender()}
                </Grid>
                <Grid xs={12} sm={12} md={12} item>
                    <Button
                        disabled={!localPassengers.some(p => !!p.selected)}
                        className={styles.nextStepBtn}
                        variant="contained"
                        color="secondary"
                        onClick={localSubmitHandler}
                        endIcon={<NavigateNextIcon/>}>
                        {/*{t`Select the flight(s) you have claim(s) for`}*/}
                        {t`Next`}
                    </Button>
                </Grid>
            </MuiPickersUtilsProvider>
        </Grid>
    );
};

PassengersList.propTypes = {
    passengers: PropTypes.array,
    onSubmit: PropTypes.func,
    screen: PropTypes.object,
};

PassengersList.defaultProps = {
    passengers: [],
    onSubmit: (paxList) => console.log(paxList),
    screen: null,
};

export default connect(state => ({passengers: state.customerClaims.modifiedFlightInfo?.passengers}))(withScreen(PassengersList));

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {SCENES} from "../../../../services/customer-claims/mock";
import Stepper from "../steps/stepper/stepper";
import Step1 from "../steps/step-1/step-1";
import {t} from 'ttag';
import ComplaintFormSelector from "../../ComplaintFormSelector/ComplaintFormSelector";


const SceneSwitcher = ({scene}) => {

    switch (scene) {
        case SCENES.PNR_FORM:
            return (
                <div className={'CustomerClaimsWithReservationPnrForm'}>
                    <ComplaintFormSelector/>
                    <Step1/>
                </div>
            );
        case SCENES.STEPPER:
            return (
                <div className={'CustomerClaimsWithReservationStepperForm'}>
                    <ComplaintFormSelector/>
                    <Stepper/>
                </div>
            );
        default:
            return t`Customer Claims`;
    }
};

SceneSwitcher.propTypes = {
    scene: PropTypes.oneOf(Object.values(SCENES)),
};

SceneSwitcher.defaultProps = {
    scene: SCENES.PNR_FORM,
};

export default connect(state => ({scene: state.customerClaims.scene}))(SceneSwitcher);

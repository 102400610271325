import * as TYPES from "./types";
import client from 'axios';
import APP_SETTINGS from "../../config/settings";
import {addNotification, setAppScene, setLoading, setProcessNumberDetails} from "../app/actions";
import {APP_SCENES} from "../app/default-state";
import {t} from 'ttag';

export function customerProcessStateSetProcess(process) {
    return dispatch => {
        dispatch({type: TYPES.SET_PROCESS, process});
        return Promise.resolve({process});
    };
}

export function customerProcessStateSetProcessNumber(processNumber) {
    return dispatch => {
        dispatch({type: TYPES.SET_PROCESS_NUMBER, processNumber});
        return Promise.resolve({processNumber});
    };
}

export function customerProcessStateFormReset() {
    return dispatch => {
        dispatch(customerProcessStateSetProcessNumber(''));
        dispatch(customerProcessStateSetProcess(null));
    };
}

export function customerProcessStateFormSubmit(processNumber) {
    return dispatch => {
        dispatch(customerProcessStateSetProcessNumber(processNumber));
        return dispatch(customerProcessStateRetrieve(processNumber));
    };
}

export function customerProcessStateRetrieve(processNumber) {
    return dispatch => {
        dispatch(setLoading(true));
        let result;

        if (APP_SETTINGS.isInMockMode()) {
            dispatch(customerProcessStateFormReset());
            dispatch(setAppScene(APP_SCENES.PROCESS_STATE_MESSAGE_WAS_SUBMITTED));
            dispatch(setLoading(false));
            return Promise.resolve();
        }

        if (APP_SETTINGS.isInApiMode()) {
            return client.post(`${APP_SETTINGS.API_ENDPOINT}/status`, {
                process: processNumber
            })
                .catch(e => {
                    console.error(e);
                    try {
                        const {response} = e;
                        const {status, data} = response;

                        if (status === 404) {
                            dispatch(addNotification(t`Process not found`, {variant: 'error'}));
                        } else {
                            const {error, description} = data;
                            dispatch(addNotification(`${error}: ${description}`, {variant: 'error'}));
                        }

                    } catch (caught) {
                        dispatch(addNotification(e.message, {variant: 'error'}));
                    }

                    return Promise.reject(e);
                })
                .then(res => {
                    result = res;
                    const {data} = result;
                    if (data.Classification === 'Expenses refund') {
                        return checkClassification(processNumber);
                    }
                    dispatch(setAppScene(APP_SCENES.PROCESS_STATE_DETAILS));
                    dispatch(setProcessNumberDetails(data, processNumber));
                    dispatch(customerProcessStateFormReset());
                    return Promise.resolve(data);
                })
                .finally(() => {
                    if (result && result.data && result.data.Classification === 'Expenses refund') {
                        return;
                    }
                    dispatch(setLoading(false));
                });
        }
    };
}

function checkClassification(processNumber) {
    const currentLanguage = APP_SETTINGS.CURRENT_LANGUAGE;
    switch (currentLanguage) {
        case 'pt':
            window.location.href = `/apoio/contacte-nos/reembolso-despesas?id=${processNumber}`;
            break;
        case 'en':
            window.location.href = `/support/contact-us/expense-request?id=${processNumber}`;
            break;
        case 'fr':
            window.location.href = `/support/nous-contacter/demande-de-despense?id=${processNumber}`;
            break;
        default:
            window.location.href = `/support/contact-us/expense-request?id=${processNumber}`;
            break;
    }
    return Promise.resolve();
}

import React, {useState} from 'react';
import {DATA, DATA_VALIDATOR, IS_CUSTOMER_VALID} from "./customer-form-data";
import {Button, Grid, TextField} from "@material-ui/core";
import PropTypes from 'prop-types';
import PhoneNumberInput from "../../../../components/phone-number-input/phone-number-input";
import {t} from 'ttag';
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import styles from './customer-form.module.scss';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const CustomerForm = ({customer, onSubmit}) => {

    const [needAttention, setNeedAttention] = useState(false);
    const [data, setData] = useState({
        ...customer
    });

    const onChange = (fieldId, value) => {
        setData({
            ...data,
            [fieldId]: value
        });
    };

    const localSubmitHandler = () => {
        if (IS_CUSTOMER_VALID(data)) {
            onSubmit({...data, name: data.name.trim()});
        } else {
            setNeedAttention(true);
        }
    };

    return (
        <Grid spacing={1} container>
            <Grid xs={12} sm={4} md={1} item>
                <FormControl
                    classes={{root: 'customerClaimsMuiFormControlSelect'}}
                    error={needAttention && !DATA_VALIDATOR.title(data.title)}
                    fullWidth
                    required>
                    <InputLabel shrink>{t`Title`}</InputLabel>
                    <Select
                        value={data.title}
                        error={needAttention && !DATA_VALIDATOR.title(data.title)}
                        onChange={(e) => onChange('title', e.target.value)}>
                        <MenuItem value={'mr'}>{t`Mr`}</MenuItem>
                        <MenuItem value={'mrs'}>{t`Mrs`}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={12} sm={4} md={2} item>
                <TextField
                    InputLabelProps={{shrink: true}}
                    label={t`Name`}
                    value={data.name}
                    error={needAttention && !DATA_VALIDATOR.name(data.name)}
                    helperText={needAttention && !DATA_VALIDATOR.name(data.name) ? t`You need to provide your first and last names.` : ''}
                    onChange={e => onChange('name', e.target.value)}
                    required
                    fullWidth
                />
            </Grid>
            <Grid xs={12} sm={4} md={3} item>
                <PhoneNumberInput
                    label={t`Phone`}
                    helperText={needAttention && !DATA_VALIDATOR.contact(data.contact) ? t`Please check field data` : t`Ex. 926542554`}
                    error={needAttention && !DATA_VALIDATOR.contact(data.contact)}
                    onChange={(phoneNumber, country) => onChange('contact', phoneNumber)}
                    value={data.contact}
                    defaultCountry={'pt'}
                />
            </Grid>
            <Grid xs={12} sm={4} md={3} item>
                <TextField
                    InputLabelProps={{shrink: true}}
                    label={t`Email`}
                    value={data.email}
                    error={needAttention && !DATA_VALIDATOR.email(data.email)}
                    helperText={needAttention && !DATA_VALIDATOR.email(data.email) ? t`Please check field data` : ''}
                    onChange={e => onChange('email', e.target.value)}
                    required
                    fullWidth
                />
            </Grid>
            <Grid xs={12} sm={4} md={3} item>
                <TextField
                    InputLabelProps={{shrink: true, style: {width: '135%'}}}
                    label={t`Frequent Flyer Number`}
                    value={data.frequentFlyer}
                    error={needAttention && !DATA_VALIDATOR.frequentFlyer(data.frequentFlyer)}
                    helperText={needAttention && !DATA_VALIDATOR.frequentFlyer(data.frequentFlyer) ? t`Please check field data` : ''}
                    onChange={e => onChange('frequentFlyer', e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <Button
                    className={styles.submitBtn}
                    variant="contained"
                    color="secondary"
                    onClick={localSubmitHandler}
                    endIcon={<NavigateNextIcon/>}>
                    {t`Next`}
                </Button>
            </Grid>
        </Grid>
    );
};

CustomerForm.propTypes = {
    onSubmit: PropTypes.func,
    customer: PropTypes.object,
};

CustomerForm.defaultProps = {
    onSubmit: () => null,
    customer: {...DATA},
}

export default CustomerForm;
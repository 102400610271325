import APP_SETTINGS from "../../../../config/settings";

export const DATA = {
    title: '',
    name: '',
    email: '',
    contact: '',
    frequentFlyer: '',
};

export const DATA_FILLED = {
    title: 'mr',
    name: 'Joshua Alves',
    email: 'joshua.alves@sata.pt',
    contact: '',
    frequentFlyer: '123456',
};

export const DATA_VALIDATOR = {
    title: s => /^(mr|mrs)$/g.test(s),
    name: s => /^[\wàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+(\s+[\wàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]+)/g.test(s),
    email: APP_SETTINGS.EMAIL_VALIDATION,
    contact: () => true,
    frequentFlyer: s => !s || (s && /^.*$/g.test(s)),
};

export const IS_CUSTOMER_VALID = inputData => {
    const titleIsValid = DATA_VALIDATOR.title(inputData.title);
    const nameIsValid = DATA_VALIDATOR.name(inputData.name);
    const emailIsValid = DATA_VALIDATOR.email(inputData.email);
    const contactIsValid = DATA_VALIDATOR.contact(inputData.contact);
    const frequentFlyerIsValid = DATA_VALIDATOR.frequentFlyer(inputData.frequentFlyer);

    return titleIsValid
        && nameIsValid
        && emailIsValid
        && contactIsValid
        && frequentFlyerIsValid;
};

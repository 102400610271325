import React, {useState} from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
//import 'react-phone-input-2/lib/style.css';
import './library-styles.scss';
import appStyles from '../../app.module.scss';
import styles from './phone-number-input.module.scss';

const PhoneNumberInput = ({label, value, disabled, readOnly, placeholder, required, error, preferredCountries, defaultCountry, searchPlaceholder, onChange, onBlur, helperText}) => {

    const inputClasses = [styles.input, appStyles.inputExcluded];
    const buttonClasses = [styles.btnClass];
    const labelClasses = [styles.label];
    const [inputFocused, setInputFocused] = useState(false);
    if (!value && !defaultCountry) buttonClasses.push(styles.noValue);
    if (inputFocused) labelClasses.push(styles.labelFocused);
    if (disabled || readOnly) {
        inputClasses.push(styles.disabled);
        buttonClasses.push(styles.disabled);
    }
    if (error) {
        labelClasses.push(styles.labelError);
        inputClasses.push(styles.inputError);
        buttonClasses.push(styles.btnError);
    }

    const localOnBlurHandler = (e) => {
        setInputFocused(false);
        onBlur(e);
    };

    return (
        <div className={`telControlContainer ${styles.container}`}>
            <span className={labelClasses.join(' ')}>{label}</span>
            <PhoneInput
                onChange={onChange}
                onFocus={() => setInputFocused(true)}
                onBlur={localOnBlurHandler}
                value={value}
                country={defaultCountry.toLowerCase()}
                preferredCountries={preferredCountries}
                required={required}
                label={label}
                placeholder={placeholder + (required ? ' *' : '')}
                error={error}
                helperText={helperText}
                disabled={disabled || readOnly}
                disableSearchIcon
                enableSearch
                searchPlaceholder={searchPlaceholder}
                inputClass={inputClasses.join(' ')}
                buttonClass={buttonClasses.join(' ')}
                //containerStyle={{marginTop: '-3px'}}
            />
        </div>

    );
};

PhoneNumberInput.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    defaultCountry: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    preferredCountries: PropTypes.array,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

PhoneNumberInput.defaultProps = {
    value: '',
    label: '',
    placeholder: '',
    required: false,
    error: false,
    helperText: '',
    defaultCountry: '',
    searchPlaceholder: '...',
    disabled: false,
    readOnly: false,
    preferredCountries: ['pt', 'it', 'es', 'de', 'fr'],
    onBlur: () => null,
    onChange: () => null,
};

export default PhoneNumberInput;

import { combineReducers } from 'redux';
import app from './app/reducer';
import customerClaims from './customer-claims/reducer';
import customerComplaint from './customer-complaint/reducer';
import customerCompliment from './customer-compliment/reducer';
import customerProcessState from './customer-process-state/reducer';

/**
 * Returns a combined reducers state
 *
 * @return {object} reducer state
 */
export default () => combineReducers({
    app,
    customerClaims,
    customerComplaint,
    customerCompliment,
    customerProcessState,
});

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {t} from "ttag";
import styles from './additional-info-and-attachments-form.module.scss';
import Button from "@material-ui/core/Button";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {DATA_VALIDATOR, IS_FORM_VALID} from "./additional-info-and-attachments-form-data";
import {connect} from "react-redux";
import {customerComplimentStep2Submit} from "../../../../../../services/customer-compliment/actions";
import AttachmentsField from "../../../../../../components/AttachmentsField/AttachmentsField";

const AdditionalInfoAndAttachmentsForm = ({onSubmit, additionalInformation, attachments, maxCharacters}) => {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [needAttention, setNeedAttention] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        additionalInformation,
        attachments
    });

    const onLocalChange = (fieldId, value) => {
        setData({
            ...data,
            [fieldId]: value
        })
    };

    const localSubmitHandler = () => {
        if (IS_FORM_VALID(data)) {
            onSubmit({
                ...data,
            });
        } else {
            setNeedAttention(true);
        }
    };

    return (
        <Grid container>
            <Grid xs={12} sm={12} md={12} item>
                <TextField
                    //label={t`Additional Information`}
                    variant="outlined"
                    value={data.additionalInformation ? data.additionalInformation : ''}
                    onChange={(e) => onLocalChange('additionalInformation', e.target.value.substring(0, maxCharacters))}
                    rows={4}
                    multiline
                    fullWidth
                    error={needAttention && !DATA_VALIDATOR.additionalInformation(data.additionalInformation)}
                    helperText={needAttention && !DATA_VALIDATOR.additionalInformation(data.additionalInformation)
                        ? t`Please check your field data`
                        : t`Characters left:` + ' ' + (maxCharacters - data.additionalInformation.length)}
                />
            </Grid>
            <Grid xs={12} sm={12} md={9} item>
                <AttachmentsField
                    loadingHandlerFn={setLoading}
                    attachments={data.attachments}
                    onChange={(attachments) => onLocalChange('attachments', attachments)}/>
            </Grid>
            <Grid xs={12} sm={12} md={12} align={'right'} item>
                <Button
                    disabled={loading || isSubmitted}
                    className={styles.submitBtn}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                            localSubmitHandler()
                            setIsSubmitted(true)
                        }}
                    endIcon={<NavigateNextIcon/>}>
                    {t`Submit`}
                </Button>
            </Grid>
        </Grid>
    )
};

AdditionalInfoAndAttachmentsForm.propTypes = {
    additionalInformation: PropTypes.string,
    attachments: PropTypes.array,
    onSubmit: PropTypes.func,
    maxCharacters: PropTypes.number,
};

AdditionalInfoAndAttachmentsForm.defaultProps = {
    additionalInformation: '',
    attachments: [],
    onSubmit: () => null,
    maxCharacters: 3000,
};

export default connect(state => ({
    additionalInformation: state.customerCompliment.complimentInfo?.additionalInformation,
    attachments: state.customerCompliment.complimentInfo?.attachments
}), {
    onSubmit: ({additionalInformation, attachments}) => customerComplimentStep2Submit(additionalInformation, attachments)
})(AdditionalInfoAndAttachmentsForm);

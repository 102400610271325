import './i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './services/store';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import App from "./App";
import themeData from './theme';
import styles from './index.module.scss';
import './config/resources/fonts/fonts.module.scss';

const theme = createMuiTheme(themeData);

ReactDOM.render((
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <ScopedCssBaseline classes={{root: styles.body}}>
                <App/>
            </ScopedCssBaseline>
        </MuiThemeProvider>
    </Provider>
), document.getElementById('root-claims-form'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import {Typography, Grid} from "@material-ui/core";
import PropTypes from 'prop-types';

const FormWasSubmittedMessage = ({title, subtitle, children}) => {
    return (
        <Grid container>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'body1'} color={'secondary'} style={{textAlign: 'left'}}>
                    {title}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'h6'} style={{textAlign: 'left'}}>
                    {subtitle}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                {children}
            </Grid>
        </Grid>
    );
};

FormWasSubmittedMessage.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    children: PropTypes.any,
};

FormWasSubmittedMessage.defaultProps = {
    title: '',
    subtitle: '',
    children: '',
};

export default FormWasSubmittedMessage;
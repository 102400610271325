import React from 'react';
import ComplaintFormSelector from "../../ComplaintFormSelector/ComplaintFormSelector";
import FormWasSubmittedMessage from "../FormWasSubmittedMessage/FormWasSubmittedMessage";
import {t} from 'ttag';
import {Typography} from "@material-ui/core";

const ClaimsFormWasSubmitted = () => {
    return (
        <div className={'CustomerClaimsFormWasSubmittedMessage'}>
            <ComplaintFormSelector/>
            <FormWasSubmittedMessage title={t`The form was successfully sent!`} subtitle={t`Your contact is always important to us.`}>
                <Typography variant={'body2'} style={{textAlign: 'left'}}>
                    {t`Within 72 hours, you will receive in your email address the confirmation of the registration of your complaint with more information.`}
                </Typography>
                <Typography variant={'body2'} style={{textAlign: 'left'}}>
                    {t`If you do not receive this email within the time limit, please check your unsolicited mailbox or send an email to customer.care@sata.pt.`}
                </Typography>
                <Typography variant={'body2'} style={{textAlign: 'left'}}>
                    {t`We hope to resolve your situation, meeting your expectations. Thank you.`}
                </Typography>
            </FormWasSubmittedMessage>
        </div>
    );
};

export default ClaimsFormWasSubmitted;
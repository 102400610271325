import React from 'react';
import PassengersList from "./passengers-form/passengers-list/passengers-list";
import {Grid, Typography} from "@material-ui/core";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {setModifiedFlightInfoPassengers} from "../../../../../services/customer-claims/actions";
import {t} from "ttag";

const Step2 = ({onSubmit}) => {

    return (
        <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'caption'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Select the passengers who were affected by the situation.`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <PassengersList onSubmit={onSubmit}/>
            </Grid>
        </Grid>
    );
};

Step2.propTypes = {
    onSubmit: PropTypes.func,
};

Step2.defaultProps = {
    onSubmit: () => null,
};

export default connect(null, {onSubmit: setModifiedFlightInfoPassengers})(Step2);

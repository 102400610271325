export default {
    "typography": {
        "fontFamily": "NeoSansRegular,NeoSansMedium,NeoSansBold,NeoSansLight,-apple-system,system-ui,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif"
    },
    "palette": {
        "common": {
            "black": "#000",
            "white": "#fff"
        },
        "background": {
            "paper": "rgba(247, 247, 247, 1)",
            "default": "#fafafa"
        },
        "primary": {
            "light": "rgba(58, 80, 132, 1)",
            "main": "rgba(0, 40, 87, 1)",
            "dark": "rgba(0, 0, 46, 1)",
            "contrastText": "rgba(254, 254, 254, 1)"
        },
        "secondary": {
            "light": "rgba(0, 100, 157, 1)",
            "main": "rgba(0, 130, 187, 1)",
            "dark": "rgba(0, 160, 217, 1)",
            "contrastText": "#fff"
        },
        "#secondary": {
            "light": "rgba(139, 249, 255, 1)",
            "main": "rgba(82, 198, 226, 1)",
            "dark": "rgba(0, 130, 187, 1)",
            "contrastText": "#fff"
        },
        "error": {
            "light": "rgba(255, 111, 96, 1)",
            "main": "rgba(229, 57, 53, 1)",
            "dark": "rgba(171, 0, 13, 1)",
            "contrastText": "#fff"
        },
        "text": {
            "primary": "rgba(0, 0, 0, 0.87)",
            "secondary": "rgba(0, 0, 0, 0.54)",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)"
        }
    }
};

import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const ArrowButton = ({children, selected, icon, selectedIcon, ...props}) => {
    return (
        <Button
            color={selected ? 'primary' : 'secondary'}
            endIcon={selected ? selectedIcon : icon}
            {...props}>
            {children}
        </Button>
    );
};

ArrowButton.propTypes = {
    children: PropTypes.any,
    selected: PropTypes.bool,
    icon: PropTypes.any,
    selectedIcon: PropTypes.any,
};

ArrowButton.defaultProps = {
    children: '',
    selected: false,
    icon: <KeyboardArrowRightIcon />,
    selectedIcon: <KeyboardArrowDownIcon />,
};

export default ArrowButton;
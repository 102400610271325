import React, {FC} from 'react';
import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {setAppScene} from "../../../services/app/actions";
import {connect} from "react-redux";
import {APP_SCENES} from '../../../services/app/default-state';
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@material-ui/icons/AirplanemodeInactive';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

interface DrupalRouteSwitcherSimulatorInterface {
    currentAppScene: string;
    setAppScene: (scene: string) => any;
}

const DrupalRouteSwitcherSimulator: FC<DrupalRouteSwitcherSimulatorInterface> = ({currentAppScene, setAppScene}) => {

    return (
        <>
            <List>
                <ListItem
                    disabled={APP_SCENES.COMPLAINT_WITH_RESERVATION === currentAppScene}
                    onClick={() => setAppScene(APP_SCENES.COMPLAINT_WITH_RESERVATION)}
                    button>
                    <ListItemIcon><AirplanemodeActiveIcon/></ListItemIcon>
                    <ListItemText primary={'Claim (with Reservation)'}/>
                </ListItem>
                <ListItem
                    disabled={APP_SCENES.COMPLAINT_WITHOUT_RESERVATION === currentAppScene}
                    onClick={() => setAppScene(APP_SCENES.COMPLAINT_WITHOUT_RESERVATION)}
                    button>
                    <ListItemIcon><AirplanemodeInactiveIcon/></ListItemIcon>
                    <ListItemText primary={'Claim (without Reservation)'}/>
                </ListItem>
                <ListItem
                    disabled={APP_SCENES.COMPLIMENT === currentAppScene}
                    onClick={() => setAppScene(APP_SCENES.COMPLIMENT)}
                    button>
                    <ListItemIcon><AssignmentTurnedInIcon/></ListItemIcon>
                    <ListItemText primary={'Compliment'}/>
                </ListItem>
                <ListItem
                    disabled={APP_SCENES.PROCESS_STATE === currentAppScene}
                    onClick={() => setAppScene(APP_SCENES.PROCESS_STATE)}
                    button>
                    <ListItemIcon><AssignmentTurnedInIcon/></ListItemIcon>
                    <ListItemText primary={'Process State'}/>
                </ListItem>
            </List>
        </>
    );
};

export default connect((state: any) => ({currentAppScene: state.app.appScene}), {setAppScene})(DrupalRouteSwitcherSimulator);

import React from 'react';
import {Grid, /*Typography*/} from "@material-ui/core";
import PropTypes from 'prop-types';
import PnrForm from "./pnr-form/pnr-form";
import {reservationGroupFilterPassengersBy, retrieveFlightInfo} from "../../../../../services/customer-claims/actions";
import {connect} from "react-redux";
// import {t} from 'ttag';

const Step1 = ({onSubmit, filterPassengers, ticketNumberError, isAGroup}) => {

    return (
        <Grid container>
            {/*<Grid xs={12} sm={12} md={12} item>
                <Typography variant={'h4'} gutterBottom style={{textAlign: 'center'}}>
                    {t`Complaint Form`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'h6'} gutterBottom>
                    {t`Dear Customer,`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'body2'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Although our goal is to provide you with the best possible experience, we do not always do it as well as we would like, which prevents us from achieving, or even overcoming, the expectations of those who expect the best from us.`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'body2'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`If your experience with us has not been the best, we offer our sincere apologies and promise to do our best to resolve your issue and improve our service. To do so, we have created two channels of communication: email Customer.care@sata.pt, if you would like to send us a suggestion to improve your service or to open your complaint process by filling out the form here.`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'body2'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`Once completed and sent, this form allows us to respond to your request more quickly, since it contains the information we need for the initiation of your process. We will make every effort to resolve your case.`}
                </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <Typography variant={'body2'} gutterBottom style={{textAlign: 'justify'}}>
                    {t`We thank you for your availability and collaboration.`}
                </Typography>
            </Grid>*/}
            <Grid xs={12} sm={12} md={12} item>
                <PnrForm onSubmit={({pnr, lastName, ticketNumber}) => ticketNumber ? filterPassengers(ticketNumber) : onSubmit(pnr, lastName)}
                         isAGroup={isAGroup}
                         ticketNumberError={ticketNumberError}
                />
            </Grid>
        </Grid>
    );
};

Step1.propTypes = {
    onSubmit: PropTypes.func,
    filterPassengers: PropTypes.func,
    ticketNumberError: PropTypes.bool,
    isAGroup: PropTypes.bool,
};

Step1.defaultProps = {
    onSubmit: () => null,
    filterPassengers: () => null,
    ticketNumberError: false,
    isAGroup: false,
};

export default connect(state => ({
    isAGroup: !!state.customerClaims.modifiedFlightInfo?.isAGroup,
    ticketNumberError: !!state.customerClaims.modifiedFlightInfo?.ticketNumberError
}), {onSubmit: retrieveFlightInfo, filterPassengers: reservationGroupFilterPassengersBy})(Step1);

import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import {IconButton} from "@material-ui/core";
import {SnackbarProvider} from "notistack";
import CloseIcon from '@material-ui/icons/Close';
import NotistackNotifier from "../notistack-notifier/notistack-notifier";

const NotistackProvider = (props) => {

    const {children, ...otherProps} = props;

    // add action to all snackbars
    const notiStackRef = createRef();
    const onClickDismiss = key => {
        notiStackRef.current.closeSnackbar(key);
    };

    return (
        <SnackbarProvider
            ref={notiStackRef}
            maxSnack={5}
            action={key => (
                <IconButton onClick={() => onClickDismiss(key)}>
                    <CloseIcon/>
                </IconButton>
            )}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            dense
            {...otherProps}>
            <NotistackNotifier/>
            {children}
        </SnackbarProvider>
    );
};

NotistackProvider.propTypes = {
    children: PropTypes.any.isRequired,
};

NotistackProvider.defaultProps = {
    children: null
};

export default NotistackProvider;

import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Checkbox, Grid} from "@material-ui/core";
import {FLIGHTS_VALIDATOR} from "./flight-list-data";
import Button from "@material-ui/core/Button";
import styles from "./flights-list.module.scss";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {t} from "ttag";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import FlightTableRow from "../flight-form/flight-table-row";
import withScreen from "../../../../../../../components/hoc/withScreen";
import FlightMobileRow from "../flight-form/flight-mobile-row";

const FlightList = ({onSubmit, flights, screen, claimSubjects}) => {

    const containerRef = useRef();
    const [needAttention, setNeedAttention] = useState(false);
    const [localFlights, setLocalFlights] = useState([
        ...flights.map(f => ({selected: false, ...f}))
    ]);

    if (!flights || (Array.isArray(flights) && !flights.length)) {
        return null;
    }

    const onChange = (idx, flight) => {
        localFlights[idx] = flight;
        setLocalFlights([...localFlights])
    };

    const selectAll = (allSelected) => {
        setLocalFlights([...localFlights.map(f => ({...f, selected: allSelected}))]);
    };

    const localSubmitHandler = () => {
        if (FLIGHTS_VALIDATOR(localFlights)) {
            onSubmit([...localFlights]);
        } else {
            window.scrollTo(0, containerRef.current.offsetTop);
            setNeedAttention(true);
        }
    };

    const responsiveRender = () => {
        if (screen.upTo(screen.breakpoints.SM)) {
            return (
                <Grid spacing={2} container>
                    {localFlights.map((f, idx) => (
                        <Grid xs={12} sm={12} md={12} key={idx} item>
                            <FlightMobileRow
                                key={idx}
                                claimSubjects={claimSubjects}
                                onFlightChange={(modifiedFlight) => onChange(idx, {...modifiedFlight})}
                                onSelect={(v) => onChange(idx, {...f, selected: v})}
                                needAttention={needAttention && f.selected}
                                selected={f.selected}
                                disabled={!f.selected}
                                flight={f}
                                readOnly/>
                        </Grid>
                    ))}
                </Grid>
            );
        }

        return (
            <TableContainer className={styles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding={"checkbox"}>
                                <Checkbox
                                    onChange={(e, value) => selectAll(!!value)}
                                    //checked={selected}
                                    value="secondary"
                                    color="secondary"
                                />
                            </TableCell>
                            <TableCell padding={'none'}>
                                {t`Flight`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Origin`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Destination`}
                            </TableCell>
                            <TableCell padding={'none'} align={'center'}>
                                {t`Claim`}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localFlights.map((f, idx) => (
                            <FlightTableRow
                                key={idx}
                                claimSubjects={claimSubjects}
                                onFlightChange={(modifiedFlight) => onChange(idx, {...modifiedFlight})}
                                onSelect={(v) => onChange(idx, {...f, selected: v})}
                                needAttention={needAttention && f.selected}
                                selected={f.selected}
                                disabled={!f.selected}
                                flight={f}
                                readOnly
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <Grid spacing={1} ref={containerRef} container>
            <Grid xs={12} sm={12} md={12} item>
                {responsiveRender()}
            </Grid>
            <Grid xs={12} sm={12} md={12} item>
                <Button
                    className={styles.nextStepBtn}
                    disabled={!localFlights.some(f => !!f.selected)}
                    variant="contained"
                    color="secondary"
                    onClick={localSubmitHandler}
                    endIcon={<NavigateNextIcon/>}>
                    {/*{t`Add other information to your claim`}*/}
                    {t`Next`}
                </Button>
            </Grid>
        </Grid>
    );
};

FlightList.propTypes = {
    flights: PropTypes.array,
    onSubmit: PropTypes.func,
    claimSubjects: PropTypes.array,
};

FlightList.defaultProps = {
    flights: [],
    onSubmit: (flightsList) => console.log(flightsList),
    claimSubjects: []
};

export default connect(state => ({claimSubjects: state.app.subjectTypesList, flights: state.customerClaims.modifiedFlightInfo?.flights}))(withScreen(FlightList));
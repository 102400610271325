import store from 'store';
import detectBrowserLanguage from "detect-browser-language";
import {DEFAULT_LANGUAGE, LANG_KEY, LANGUAGES, LANGUAGES_MAP, LOCALE_MAP} from "./i18n-settings";

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_FORMAT_NO_SEC = 'YYYY-MM-DD HH:mm';
export const EMAIL_VALIDATION = s => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(s);
export const URL_VALIDATION = s => /^(?:http(s)?:\/\/)?((localhost)|([\w.-]+(?:\.[\w.-]+)+))[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(s);

export const DEBUG_KEY = 'DEBUG_KEY';
export const MODE_KEY = 'MODE_KEY';
export const FIXED_TOOLBAR_KEY = 'FIXED_TOOLBAR_KEY';

export const APP_MODES = {
    MOCK: 'MOCK',
    API: 'API'
};

const APP_SETTINGS = {
    DEBUG: !!store.get(DEBUG_KEY),
    MODE: store.get(MODE_KEY) || APP_MODES.API,
    FIXED_TOOLBAR: !!store.get(FIXED_TOOLBAR_KEY),
    MODES: {...APP_MODES},
    SET_MODE: mode => {
        store.set(MODE_KEY, mode);
        APP_SETTINGS.MODE = mode;
        return mode;
    },
    SET_DEBUG: debug => {
        store.set(DEBUG_KEY, debug);
        APP_SETTINGS.MODE = debug;
        return debug;
    },
    SET_FIXED_TOOLBAR: fixed => {
        store.set(FIXED_TOOLBAR_KEY, fixed);
        APP_SETTINGS.FIXED_TOOLBAR = fixed;
        return fixed;
    },
    NAME: process.env.REACT_APP_NAME,
    API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
    LANGUAGES,
    LANGUAGES_MAP,
    DEFAULT_LANGUAGE,
    LOCALE_MAP,
    CURRENT_LOCALE: LOCALE_MAP[DEFAULT_LANGUAGE],
    SET_LANGUAGE: lang => {
        store.set(LANG_KEY, lang);
        APP_SETTINGS.CURRENT_LANGUAGE = lang;
        APP_SETTINGS.CURRENT_LOCALE = LOCALE_MAP[lang];
        return lang;
    },
    CURRENT_LANGUAGE: DEFAULT_LANGUAGE,
    DRUPAL_CURRENT_ROUTE: window.CURRENT_PATH || '',
    DRUPAL_CLAIMS_FORM_TYPE: window.CLAIMS_FORM_TYPE || '',
    ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
    DATE_FORMAT,
    DATE_TIME_FORMAT,
    DATE_TIME_FORMAT_NO_SEC,
    EMAIL_VALIDATION,
    URL_VALIDATION,
    NOTIFICATION_TIMEOUT: 6000,
    isInMockMode: () => APP_SETTINGS.MODE === APP_MODES.MOCK,
    isInApiMode: () => APP_SETTINGS.MODE === APP_MODES.API,
};

APP_SETTINGS.SET_LANGUAGE(window.LANG?.substring(0,2) || store.get(LANG_KEY) || detectBrowserLanguage().substring(0, 2));

export default APP_SETTINGS;

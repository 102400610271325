import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {t} from "ttag";
import styles from './attachments-field.module.scss';
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';
import {byteToMB, loadFile} from "../../config/tools";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from "notistack";
import NotiStackWithContent
    from "../notistack/notistack-with-content/notistack-with-content";
import IconButton from "@material-ui/core/IconButton";

const AttachmentsField = ({attachments, onChange, fileSizeLimit, totalSizeLimit, allowedFileTypes, loadingHandlerFn}) => {

    const {enqueueSnackbar} = useSnackbar();
    const [loadingFiles, setLoadingFiles] = useState(false);

    const localDataChangeHandler = (attachments) => {
        onChange([...attachments]);
    };

    const clearFiles = (e) => {
        e.preventDefault();
        e.stopPropagation();

        localDataChangeHandler([]);
    };

    const clearFile = (e, attachmentIdx) => {
        e.preventDefault();
        e.stopPropagation();

        attachments.splice(attachmentIdx, 1)
        localDataChangeHandler([...attachments]);
    };

    const localLoadingHandler = (loading) => {
        setLoadingFiles(loading);
        loadingHandlerFn(loading);
    };

    const onDropRejected = (rejectedFiles, event) => {
        const rejectedFilesWithReasons = rejectedFiles.map(f => {
            let reason = 'Unknown error';
            const extension = f.name.split('.').pop();
            if (f.size > fileSizeLimit) reason = byteToMB(fileSizeLimit) + 'MB ' + t`exceeded`;
            if (!allowedFileTypes.includes(extension)) reason = t`.${extension} not allowed`;
            return {name: f.name, size: f.size, reason};
        });

        enqueueSnackbar(t`There was a problem loading the files`, {
            content: (key, message) => (
                <NotiStackWithContent id={key} message={message} variant={'error'}>
                    {rejectedFilesWithReasons.map((f, idx) => <MuiAlert key={idx} severity='error'>{f.name} ({byteToMB(f.size)}MB) [{f.reason}]</MuiAlert>)}
                </NotiStackWithContent>
            ),
        });
    };

    const validateDroppedFiles = (files) => {
        const totalAttachmentsSize = attachments.reduce((accumulator, currentFile) => accumulator + currentFile.size, 0);
        const totalFilesAndAttachmentsSize = files.reduce((accumulator, currentFile) => accumulator + currentFile.size, totalAttachmentsSize);
        const isTotalFilesAndAttachmentsSizeAllowed = totalFilesAndAttachmentsSize <= totalSizeLimit;

        let allowedDroppedFiles = files;
        let rejectedDroppedFiles = [];

        if (!isTotalFilesAndAttachmentsSizeAllowed) {
            allowedDroppedFiles = [];
            let totalSize = totalAttachmentsSize;
            const sortedFiles = files.sort((fa, fb) => fa.size - fb.size);
            sortedFiles.forEach(file => {
                totalSize += file.size;
                if (totalSize <= totalSizeLimit) {
                    allowedDroppedFiles.push(file);
                } else {
                    rejectedDroppedFiles.push(file);
                }
            });

            const availableSize = totalSizeLimit - allowedDroppedFiles.reduce((a, f) => a + f.size, totalAttachmentsSize);
            if (rejectedDroppedFiles.length) {
                const availableSizeInMB = byteToMB(availableSize);
                enqueueSnackbar(t`Total size limit was reached. (only ${availableSizeInMB}MB available)`, {
                    content: (key, message) => (
                        <NotiStackWithContent id={key} message={message} variant={'error'}>
                            {rejectedDroppedFiles.map((f, idx) => <MuiAlert key={idx} severity='error'>{f.name} ({byteToMB(f.size)}MB)</MuiAlert>)}
                        </NotiStackWithContent>
                    ),
                });
            }
        }

        return {allowedDroppedFiles, rejectedDroppedFiles};
    }

    const onDrop = (files) => {
        if (!files || !Array.isArray(files)) {
            return false;
        }

        const {allowedDroppedFiles} = validateDroppedFiles(files);

        localLoadingHandler(true);
        Promise.all(allowedDroppedFiles.map(f => loadFile(f)))
            .then((loadedFiles) => {
                localDataChangeHandler([...attachments, ...loadedFiles]);
                // enqueueSnackbar('Files loaded', {
                //     content: (key, message) => (
                //         <NotiStackWithContent id={key} message={message} variant={'success'}>
                //             {loadedFiles.map((lf, idx) => <MuiAlert key={idx} severity='success'>{lf.name}</MuiAlert>)}
                //         </NotiStackWithContent>
                //     )
                // });
            })
            .catch(e => {
                enqueueSnackbar(e.target?.error?.message || e.message || t`There was a problem loading your files`, {variant: 'error'});
            })
            .finally(() => {
                localLoadingHandler(false)
            });
    };

    const {getRootProps, getInputProps} = useDropzone({onDrop, onDropRejected, maxSize: fileSizeLimit,  accept: allowedFileTypes});

    const fileNamesJsx = attachments.map((attach, idx) => (
        <span key={idx}>
            <IconButton size={'small'} onClick={e => clearFile(e, idx, attach)}>
                <DeleteIcon />
            </IconButton>
            <b>{attach.name}</b> ({byteToMB(attach.size)}MB)
            <br/>
        </span>
    ));

    const resetBtn = attachments.length ? (
        <Button
            //className={styles.deleteBtn}
            variant="contained"
            color="default"
            onClick={clearFiles}
            size={"small"}
            startIcon={<DeleteIcon/>}>
            {t`Reset Files`}
        </Button>
    ) : null;

    const totalFileSizeLoaded = attachments.reduce((accumulator, attach) => accumulator + attach.size, 0);

    return (
        <>
            <div {...getRootProps({className: styles.dropzone})}>
                {resetBtn}
                <input {...getInputProps()} />
                {loadingFiles
                    ? <CircularProgress color="inherit"/>
                    : <p style={{margin: '10px 3px'}}>{t`Drop Attachments`} ({byteToMB(totalFileSizeLoaded)}MB/{byteToMB(fileSizeLimit)}MB {t`used`})</p>}
                <small><i>{t`Allowed types: ${allowedFileTypes}`}</i></small>
            </div>
            {fileNamesJsx}
        </>
    )
};

AttachmentsField.propTypes = {
    attachments: PropTypes.array,
    allowedFileTypes: PropTypes.string,
    fileSizeLimit: PropTypes.number,
    totalSizeLimit: PropTypes.number,
    onChange: PropTypes.func,
    loadingHandlerFn: PropTypes.func,
};

AttachmentsField.defaultProps = {
    attachments: [],
    allowedFileTypes: '.jpg, .jpeg, .png, .doc, .docx, .pdf, .txt',
    fileSizeLimit: 10000000,
    totalSizeLimit: 10000000,
    onChange: () => null,
    loadingHandlerFn: () => null,

};

export default AttachmentsField;

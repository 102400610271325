import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SUBJECT_TYPES from "../../../config/DATA/SUBJECT_TYPES.js";
import {t} from "ttag";
import CircularProgress from "@material-ui/core/CircularProgress";

const ClaimSubtypeAutocomplete = ({subjectTypes, placeHolder, claimType, label, value, onSelect, required, error, disabled, readOnly, onBlur, loading, fullWidth}) => {

    const subjectType = subjectTypes.find(l => l.subjectType === claimType);
    value = (subjectType && subjectType.subjectTypeDetails.find(l => l.reasonId === value)?.reason) || '';

    return (
        <Autocomplete
            onBlur={onBlur}
            disabled={disabled}
            readOnly={readOnly}
            autoHighlight
            options={subjectType ? subjectType.subjectTypeDetails : []}
            onChange={(e, o) => onSelect(o ? o.reasonId : '')}
            getOptionSelected={(o, selectedOpt) => o.reasonId === selectedOpt.reasonId}
            getOptionLabel={o => (typeof o === 'object') ? o.reason : o}
            value={value}
            renderOption={(opt, inputValue) => {
                return (
                    <small style={{width: '100%'}}>
                        {opt.reason}
                    </small>
                );
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    error={error}
                    required={required}
                    autoFocus={false}
                    placeholder={placeHolder}
                    label={label}
                    fullWidth={fullWidth}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color='inherit' size={15} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                    ),
                    //readOnly: readOnly
                }}/>
            )}
        />
    );
};

ClaimSubtypeAutocomplete.propTypes = {
    subjectTypes: PropTypes.array,
    value: PropTypes.any,
    claimType: PropTypes.string,
    label: PropTypes.string,
    placeHolder: PropTypes.string,
    onSelect: PropTypes.func,
    onBlur: PropTypes.func,
    required: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    fullWidth: PropTypes.bool,
    loading: PropTypes.bool,
};

ClaimSubtypeAutocomplete.defaultProps = {
    subjectTypes: SUBJECT_TYPES,
    value: null,
    claimType: null,
    label: t`Claim Reason select`,
    placeHolder: t`Start typing...`,
    onSelect: () => null,
    onBlur: () => null,
    required: false,
    error: false,
    disabled: false,
    readOnly: false,
    fullWidth: false,
    loading: false,
};

export default ClaimSubtypeAutocomplete;
